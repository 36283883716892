import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, Row, Col } from 'reactstrap';
import Alert from 'reactstrap/lib/Alert';

import { useIntl, LoadingSpinner, Masonry, NativeSlider } from '@ttstr/components';
import { receiveProducts } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { products, loading } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
      <section>
        <Helmet>
          <body className="ww-page product-listing-page inverse-navbar" />
        </Helmet>

        <div className="lina-collection d-flex justify-content-center align-items-center flex-column">
          <div className="overlay d-flex justify-content-center align-items-center flex-column">
            <h1 className="mb-3">LINA Drop 23/24</h1>
            <a href="/category/4232" className="btn btn-lg btn-primary d-block">Jetzt entdecken</a>
          </div>
        </div>

        <div className="kids-collection d-flex justify-content-center align-items-center flex-column">
          <div className="logo"></div>
          <div className="overlay d-flex justify-content-center align-items-center flex-column">
            <h1 className="mb-3">Die neue Kids Collection</h1>
            <a href="/category/4233" className="btn btn-lg btn-primary d-block">Jetzt entdecken</a>
          </div>
        </div>

     </section>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(Home);
